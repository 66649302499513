'use client';

import { isSupportOrAdmin } from '@/helpers/auth';
import { Locale } from '@/i18n';
import { Link, usePathname, useRouter } from '@/i18n/routing';
import { signIn, signOut, useSession } from 'next-auth/react';
import { useLocale, useTranslations } from 'next-intl';
import Image from 'next/image';
import { TbLanguage, TbLogin, TbLogout, TbMenu2, TbUser } from 'react-icons/tb';

import logo from '@/app/logo.svg';
import { useFeatureFlags } from '@/providers/feature-flags-provider';

type MenuItem = {
  text: string;
  shown: boolean;
} & (
  | {
      href: string;
      sub?: undefined;
    }
  | {
      href?: undefined;
      sub: Omit<MenuItem, 'sub' | 'shown'>[];
    }
);

interface LocaleItem {
  locale: Locale;
  text: string;
  long: string;
}

const NavBar = () => {
  const t = useTranslations('common');
  const featureFlags = useFeatureFlags();
  const locale = useLocale();
  const router = useRouter();
  const pathname = usePathname();
  const { data: session } = useSession();

  function changeLanguage(locale: Locale) {
    router.push(pathname, {
      locale,
    });
  }

  const authenticated = !!session;
  const admin = isSupportOrAdmin(session);

  // TODO: implement shown for sub items
  const menu: MenuItem[] = [
    {
      text: t('self-services'),
      href: '/selfservices',
      shown: featureFlags.selfservices,
    },
    {
      text: t('recertifications'),
      href: '/recertifications',
      shown: authenticated,
    },
    {
      text: t('groups'),
      shown: authenticated,
      sub: [
        {
          text: t('groups-all'),
          href: '/groups',
        },
      ],
    },
    {
      text: t('service-accounts'),
      shown: authenticated,
      sub: [
        {
          text: t('service-accounts-all'),
          href: '/serviceaccounts',
        },
        {
          text: t('service-account-new'),
          href: '/serviceaccounts/new',
        },
      ],
    },
    {
      text: t('admin'),
      shown: authenticated && admin,
      sub: [
        {
          text: t('billing-types'),
          href: '/billing-types',
        },
        {
          text: t('data-export'),
          href: '/export',
        },
        {
          text: t('jobs'),
          href: '/jobs',
        },
        {
          text: t('organisations'),
          href: '/organisations',
        },
        {
          text: t('users'),
          href: '/users',
        },
        {
          text: t('group-new'),
          href: '/groups/orders/new',
        },
      ],
    },
  ];

  const menuFiltered = menu.filter((item) => item.shown);

  const LANGUAGES: LocaleItem[] = [
    { locale: 'en', text: 'EN', long: 'English' },
    { locale: 'de', text: 'DE', long: 'Deutsch' },
  ];

  return (
    <div className="navbar bg-primary text-primary-content">
      <div className="navbar-start">
        <div className="dropdown">
          <div tabIndex={0} role="button" className="btn btn-ghost lg:hidden">
            <TbMenu2 size={24} />
          </div>
          <ul
            tabIndex={0}
            className="menu dropdown-content bg-base-100 dark:bg-base-200 text-base-content rounded-box z-[2] min-w-64 p-2 shadow dark:shadow-telekom-gray"
          >
            {menuFiltered.map((item, index) => (
              <li key={index}>
                {item.href ? (
                  <Link key={item.text} href={item.href!}>
                    {item.text}
                  </Link>
                ) : (
                  <span className="menu-title">{item.text}</span>
                )}

                {item.sub && (
                  <ul>
                    {item.sub.map((subItem, subIndex) => (
                      <li key={`${index}-${subIndex}`}>
                        <Link key={subItem.text} href={subItem.href!}>
                          {subItem.text}
                        </Link>
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            ))}
          </ul>
        </div>
        <Link className="btn btn-ghost" href="/">
          <Image
            className="w-20 h-10"
            src={logo}
            width={80}
            height={40}
            alt="Telekom MIS Portal"
          />
        </Link>
      </div>
      <div className="navbar-center hidden lg:flex">
        {menuFiltered.map((item, index) => {
          if (item.sub) {
            return (
              <div key={index} className="dropdown dropdown-hover">
                <label tabIndex={0} className="btn btn-ghost">
                  {item.text}
                </label>
                <ul
                  tabIndex={0}
                  className="menu dropdown-content bg-base-100 dark:bg-base-200 text-base-content rounded-box z-[2] min-w-64 p-2 shadow dark:shadow-telekom-gray"
                >
                  {item.sub?.map((subitem) => (
                    <li key={subitem.text}>
                      <Link key={subitem.text} href={subitem.href!}>
                        {subitem.text}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            );
          } else {
            return (
              <Link key={index} className="btn btn-ghost" href={item.href}>
                {item.text}
              </Link>
            );
          }
        })}
      </div>
      <div className="navbar-end">
        {session && (
          <div key="loggedIn" className="dropdown dropdown-hover dropdown-end">
            <div
              tabIndex={0}
              role="button"
              className="btn btn-ghost rounded-btn"
            >
              <TbUser size={20} aria-hidden /> {session?.user?.name}
            </div>
            <ul
              tabIndex={0}
              className="menu dropdown-content bg-base-100 dark:bg-base-200 text-base-content rounded-box z-[2] min-w-48 p-2 shadow dark:shadow-telekom-gray"
            >
              <li>
                <a onClick={() => signOut()}>
                  <TbLogout size={18} aria-hidden /> {t('logout')}
                </a>
              </li>
            </ul>
          </div>
        )}
        {!session && (
          <button
            key="loggedOut"
            className="btn btn-ghost"
            onClick={() => signIn('keycloak')}
          >
            <TbLogin size={20} aria-hidden /> {t('login')}
          </button>
        )}

        <div key="languages" className="dropdown dropdown-hover dropdown-end">
          <div tabIndex={0} role="button" className="btn btn-ghost rounded-btn">
            <TbLanguage size={20} aria-hidden />{' '}
            {LANGUAGES.find((item) => item.locale === locale)?.text || 'EN'}
          </div>
          <ul
            tabIndex={0}
            className="menu dropdown-content bg-base-100 dark:bg-base-200 text-base-content rounded-box z-[2] min-w-48 p-2 shadow dark:shadow-telekom-gray"
          >
            {LANGUAGES.map((item) => (
              <li key={item.locale}>
                <a onClick={() => changeLanguage(item.locale)}>{item.long}</a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default NavBar;
